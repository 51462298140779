import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../components/shared/hero';
import { graphql, Link } from 'gatsby';
import SectionTitles from '../components/shared/section-titles';
import { BgWrapper } from '../components/shared/components';
import constants from '../constants/careers'
import CollapsibleSection from '../components/shared/collapsable';
import ScrollAnimation from 'react-animate-on-scroll';
import '../styles/common.css';
import metaDescriptions from '../constants/meta-descriptions';

export default ({ data }) => {
  return (
    <BgWrapper
      bg={data.bg.childImageSharp.fluid.src}
    >
      <Layout>
        <SEO
          title={metaDescriptions.careers.title}
          metaDescription={metaDescriptions.careers.metaDescription}
        />

        <Hero
          heroImage={data.heroImage.childImageSharp.fluid.src}
          imageClass='hero-business'
          title={(
            <>
              <h1 className='text-white' style={{ whiteSpace: 'pre-line' }}>
                <span className='d-none d-sm-inline-block mt-5 mx-1'>Това не е просто компания –<br />това е семейство</span>
                <span className='d-inline d-sm-none mt-5 mx-2'>Това не е просто компания – това е семейство</span>
              </h1>
            </>
          )}
        />

        <ScrollAnimation
          animateIn='fadeIn'
          animateOnce={true}
        >
          <SubHero data={data} />
        </ScrollAnimation>

        <ScrollAnimation
          animateIn='fadeIn'
          animateOnce={true}
        >
          <Testimonials data={data} />
        </ScrollAnimation>


        <ScrollAnimation
          animateIn='fadeIn'
          animateOnce={true}
        >
          <EmployeeSection data={data} />
        </ScrollAnimation>

        <ScrollAnimation
          animateIn='fadeIn'
          animateOnce={true}
        >
          <CollapsibleSection
            faqs={data.allWordpressWpFaqs.edges.map(({ node }) => node)}
            sectionTitle={(
              <>
                <h2 style={{ whiteSpace: 'pre-wrap' }}>Често задавани въпроси</h2>
                <hr className='center' />
              </>
            )}
          />
        </ScrollAnimation>

        {data.careers &&
          <ScrollAnimation
            animateIn='fadeIn'
            animateOnce={true}
          >
            <CareerTable data={data} />
          </ScrollAnimation>
        }

      </Layout>
    </BgWrapper>
  )
}

export const query = graphql`
  query CareersAll {
    heroImage: file(relativePath: {eq: "careers/hero.png"}) {
      childImageSharp {
        fluid(maxHeight: 1852) {
          src
        }
      }
    }
    bossImage: file(relativePath: {eq: "about/the-boss.png"}) {
      childImageSharp {
        fluid(maxWidth: 451) {
          src
        }
      }
    }
    bg: file(relativePath: {eq: "careers/bg.png"}) {
      childImageSharp {
        fluid(maxWidth: 2200) {
          src
        }
      }
    }
    testimonialsBg: file(relativePath: {eq: "careers/bg-testimonial-element.png"}) {
      childImageSharp {
        fluid(maxWidth: 852) {
          src
        }
      }
    }
    peopleImage: file(relativePath: {eq: "careers/people.png"}) {
      childImageSharp {
        fluid(maxWidth: 2200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allWordpressWpFaqs(filter: {acf: {category: {eq: "careers"}}}, sort: {order: DESC, fields: wordpress_id}) {
      edges {
        node {
          title
          content
        }
      }
    }
  } `;

    // careers: allWordpressWpCareers(sort: {fields: [acf___location, title]}) {
    //   edges {
    //     node {
    //       title
    //       slug
    //       acf {
    //         location
    //       }
    //     }
    //   }
    // }

const EmployeeSection = ({ data }) => {
  return <section id='careers-employees' className='spacing-bottom spacing-top'>
    <div className='container d-flex justify-content-center align-items-center flex-column'>
      <SectionTitles undertext={'Искаме хората да процъфтяват в Нетсърф; вярваме, че вършите най-добрата си работа, когато се чувствате най-добре. Ето само няколко предимства, които с гордост предоставяме на нашия екип.'}>
        <h2>Какво предлагаме на нашите служители</h2>
        <hr className='center' />
      </SectionTitles>
      <div className='my-5 row text-left'>
        <div className='col-md-6'>
          <ul className='blue-ticks'>
            {constants.employeeList1.map((text, index) => (
              <li className='my-2' key={text + index}>{text}</li>
            ))}
          </ul>
        </div>
        <div className='col-md-6'>
          <ul className='blue-ticks'>
            {constants.employeeList2.map((text, index) => (
              <li className='my-2' key={text + index}>{text}</li>
            ))}
          </ul>
        </div>
      </div>
      <div className='w-100'>
        <img src={data.peopleImage.childImageSharp.fluid.src} className='img-fluid' alt='Нашият екип' />
      </div>
    </div>
  </section>
}

const SubHero = ({ data }) => {
  return <section id='careers-quote' className='spacing-bottom spacing-top'>
    <div className='container'>
      <SectionTitles>
        <h2>Ела и ние ще ти дадем цялата подкрепа и условия за <span className='text-purple'>професионално развитие и успех</span></h2>
        <hr className='center' />
      </SectionTitles>
      <div className='row w-100 mb-5 d-flex justify-content-center'>
        <div className='col-12 col-lg-5 d-flex align-items-center order-1 order-lg-0'>
          <div>
            <p>“Хората по целия свят харчат много време и енергия за работа. Тя може да бъде източник на идентичност и вдъхновение, но може да бъде и източник на безпокойство и безсилие, затова е важно хората да съзнават важността на своя личен принос. Да помогнем за подобряване на работния живот на хората е едно от нещата, които ние като компания си поставихме за цел.</p>
            <p>NetSurf е изградена върху основата на предприемачество, иновации и почтеност, а работещите тук са страстни към нашите клиенти и към постигане на успех заедно. Ние изграждаме силен, разнообразен екип от любопитни, изобретателни и творчески хора, които искат да намерят цел в работата си и да се подкрепят взаимно в процеса. Работим усилено и играем, за да спечелим... в рамките на нормалното работно време. И тогава се прибираме вкъщи, за да пренесем щастието си там.”</p>
            <div className='text-right'>
              <p className='m-0 p-0'>С уважение,</p>
              <h3>Цветомир Цветков,</h3>
              <h3>основател на NetSurf</h3>
            </div>
          </div>
        </div>
        <div className='col-12 col-lg-4 order-0 order-lg-1 text-center'>
          <img src={data.bossImage.childImageSharp.fluid.src} className='img-fluid' alt='Цветомир Цветков' />
        </div>
      </div>
    </div>
  </section>;
}

const Testimonials = ({ data }) => {
  return <section id='careers-testimonials' className='spacing-bottom spacing-top'>
    {/* <BgWrapper bg={data.testimonialsBg.childImageSharp.fluid.src}> */}
    <div className='container d-flex justify-content-center align-items-center flex-column'>
      <SectionTitles undertext={'Ние в NetSurf вярваме в себе си, в екипите си и в решенията, които предлагаме. Искрени сме и с това печелим доверие. Заедно се фокусираме върху общата цел, не се плашим да поемаме отговорност и се гордеем с общите резултати. Съдействаме и помагаме на колегите си в нужда. Внедрената в манталитета ни организираност и утвърденият от годините работа синхрон в екипа значат само едно - не губим ничие време. Действаме и мислим приспособимо и гъвкаво, за да бъдем ефективни. Отнасяме се почтено и етично към служителите, клиентите и обществото. Стремим се да изпреварваме събитията и да сме пионери в своята област.'}>
        <h2>Нашите водещи принципи</h2>
        <hr className='center' />
      </SectionTitles>
      <div className='col-md-7 my-5 row text-left'>
        <div className='col-sm-6 my-2'><p className='d-inline-block'><span role="img" aria-label='emoji'>👪</span> Отборен дух</p></div>
        <div className='col-sm-6 my-2'><p className='d-inline-block'><span role="img" aria-label='emoji'>🔒</span> Автентичност</p></div>
        <div className='col-sm-6 my-2'><p className='d-inline-block'><span role="img" aria-label='emoji'>🥇</span> Ефективност</p></div>
        <div className='col-sm-6 my-2'><p className='d-inline-block'><span role="img" aria-label='emoji'>🏎️</span> Бързина</p></div>
        <div className='col-sm-6 my-2'><p className='d-inline-block'><span role="img" aria-label='emoji'>❤️</span> Съпричастие</p></div>
        <div className='col-sm-6 my-2'><p className='d-inline-block'><span role="img" aria-label='emoji'>🌍</span> Гъвкавост</p></div>
        <div className='col-sm-6 my-2'><p className='d-inline-block'><span role="img" aria-label='emoji'>👪</span> Отговорност</p></div>
        <div className='col-sm-6 my-2'><p className='d-inline-block'><span role="img" aria-label='emoji'>🔥️</span> Проактивност</p></div>
      </div>
    </div>
    {/* </BgWrapper> */}
  </section>;
}

const CareerTable = ({ data }) => {
  return <section id='careers-quote' className='spacing-bottom spacing-top'>
    <div className='container'>
      <SectionTitles>
        <h2>Кариерни възможности</h2>
        <hr className='center' />
      </SectionTitles>
      <div className='table-responsive-lg'>
        <table className="table table-striped careers">
          <thead>
            <tr className='rows'>
              <th>Позиция</th>
              <th>Град</th>
              <th>Кандидатствай</th>
            </tr>
          </thead>
          <tbody>
            {data.careers.edges.map(edge => (
            <tr className='rows' key={edge.node.slug}>
              <td>
                <h6>
                  <span className='icon briefcase mb-1 mr-1'></span>
                  <span dangerouslySetInnerHTML={{ __html: edge.node.title }} />
                </h6>
              </td>
              <td>
                <p><span className='icon map-pin mb-1 mr-1'></span>{edge.node.acf.location}</p>
              </td>
              <td><Link to={'/careers/' + edge.node.slug}>Виж повече<span className='icon back-blue-arrow reverse ml-1'></span></Link></td>
            </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </section>;
}
