import React from 'react';

export default {

  employeeList1: [
    'Въвеждащо и последващи обучения за повишаване на квалификацията',
    'Възможност за кариерно развитие в компанията',
    'Много добро възнаграждение и бонусна система',
  ],
  employeeList2: [
    'Ваучери за храна',
    '50% отстъпка от домашните ни услуги – Интернет & TV',
    'Отстъпки за пазаруване в партньорската ни мрежа',
  ],
  faqs: [
    {
      title: 'Как да кандидатствам за публикувани свободни позиции в NetSurf?',
      content: <p className=''>На кариерната ни страница се публикуват и обновяват всички свободни позиции в NetSurf. Към всяка обява за работа има онлайн-форма за кандидатстване. Попълни задължителните полета, прикачи своето CV и преди да изпратиш своята кандидатура се увери, че всички данни са попълнени коректно.</p>,
    },
    {
      title: 'Кой може да кандидатства?',
      content: <p className=''>В NetSurf търсим мотивирани хора, които се стремят към постоянно развитие и постигане на високи резултати. Ако се разпознаваш в профил с изисквания на някоя от нашите обяви, кандидатствай смело.</p>,
    },
    {
      title: 'В момента нямате отворени позиции, въпреки това мога ли да си изпратя CV-то?',
      content: <p className=''>Разбира се. Можеш да си изпратиш CV-то на <a href="mailto:careers@net-surf.net">careers@net-surf.net</a> и кратко мотивационно съобщение. Ще разгледаме кандидатурата ти и ще се свържем с теб.</p>,
    },
    {
      title: 'Какви умения и квалификация са необходими, за да работя в NetSurf?',
      content: <p className=''>Всяка позиция има различни критерии по отношение на квалификация, умения и способности. За повече информация разгледай подробно описанието на позицията, за която кандидатстваш.</p>,
    },
    {
      title: 'Какво следва, след като съм изпратил своята кандидатура?',
      content: <p className=''>Щом получим твоята кандидатура, нашите специалистите по подбор на персонал ще я прегледат. Ако си одобрен, ще се свържем с теб, за да продължим към интервютата.</p>,
    },
    {
      title: 'Как се съхранява моята лична информация?',
      content: <p className=''>Предоставените от теб данни са лични и попадат под специална защита по смисъла на ЗЗЛД. Ние, в NetSurf, се задължаваме да не разпространяваме твоите данни и да ги обработваме само и единствено за целите на настоящия подбор.</p>,
    },
  ]
}